<template>
    <PageWithButtonBelow>
        <template v-slot:default>
            <div class="text-white pr-32 px-8 ">
                <h3 class="text-3xl font-bold">{{ t('match_is_send') }}</h3>
                <p class="pt-10 font-semibold">{{ t('coach_will_review_in_two_weeks', {coach_name}) }}</p>    
            </div>
        </template>

        <template v-slot:button>
            <router-link :to="{name: 'dashboard'}">
                <span class="w-full rounded-md shadow-sm">
                    <button type="submit" class="button w-full">
                        {{ t('to_dashboard') }}
                    </button>
                </span>
            </router-link>
        </template>
    </PageWithButtonBelow>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import { showExpert } from "@/helpers/api/ExpertHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewCreateSuccess",
    setup() {
        return useI18n({
            viewPrefix: "ReviewCreateSuccess"
        });
    },
    components: { PageWithButtonBelow },
    created(){
        if(this.$route.query.coach_id){
            showExpert(this.$route.query.coach_id)
                .then((data) => {
                    this.coach_name = data.name;
                });
        }  
    },
    data(){
        return {
            coach_name: this.t('the_expert'),
        }
    },
}
</script>
